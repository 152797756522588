<template>
  <v-dialog v-model="visible" max-width="420" persistent>
    <v-card
      rounded="lg"
      class="d-flex"
      :class="$vuetify.breakpoint.smAndDown ? 'pa-4' : 'pa-8 pb-6'"
      style="flex-direction: column"
      :style="$vuetify.breakpoint.smAndDown ? 'gap: 24px' : 'gap: 32px'"
    >
      <div class="d-flex" style="flex-direction: column; gap: 8px">
        <div class="d-flex align-center" style="gap: 16px">
          <feather
            :type="msg.icon || 'alert-triangle'"
            size="24"
            stroke="var(--v-warning-base)"
          />
          <div class="title-1 font-weight-bold onSurface_dark--text">알림</div>
        </div>
        <div class="subtitle-1 onSurface_light--text" v-html="msg.msg" />
      </div>
      <div class="d-flex justify-end" style="gap: 8px">
        <v-btn
          height="40px"
          elevation="0"
          outlined
          class="subtitle-1 font-weight-bold px-4"
          @click="cancel"
        >
          {{ msg.cancelMsg }}
        </v-btn>
        <v-btn
          height="40px"
          elevation="0"
          color="primary"
          class="subtitle-1 font-weight-bold px-4"
          @click="confirm"
        >
          {{ msg.confirmMsg }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "WarningCard",
  props: {
    msg: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.closeDialog();
    },
    cancel() {
      this.$emit("cancel");
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("update:visible", false);
    },
  },
};
</script>

<style scoped></style>
