<template>
  <div class="d-flex align-center" :style="edit && 'cursor: pointer'">
    <div
      v-if="!isEditing"
      @click="toggleEditMode"
      class="font-weight-regular onSurface_normal--text d-flex align-center"
      style="width: 100%"
    >
      {{ formattedValue || "-" }}
    </div>

    <!-- 수정 모드일 때 입력 필드 표시 -->
    <div v-else class="d-flex" style="position: relative">
      <div
        v-if="isEditing && inputType === 'status'"
        style="z-index: 10; position: absolute; left: -110px"
      >
        <v-card class="px-1 py-1 d-flex flex-column" style="gap: 4px">
          <div
            v-for="(item, index) in status"
            :key="index"
            @click="
              editedValue = item.title;
              saveValue();
            "
          >
            <v-hover v-slot="{ hover }" close-delay="100">
              <div
                :style="hover && 'background: var(--v-background_normal-base)'"
                class="d-flex align-center px-2 py-1"
                style="gap: 12px; z-index: 10"
              >
                <feather
                  :type="item.icon"
                  size="18"
                  :stroke="
                    item.title == editedValue
                      ? 'var(--v-primary-base)'
                      : 'var(--v-onSurface_normal-base)'
                  "
                />
                <div
                  class="subtitle-1 font-weight-regular d-flex align-center"
                  :class="
                    item.title == editedValue
                      ? 'primary--text'
                      : 'onSurface_normal--text)'
                  "
                >
                  {{ item.text }}
                </div>
              </div>
            </v-hover>
          </div>
        </v-card>
      </div>
      <div
        v-if="isEditing && inputType === 'workspace'"
        style="z-index: 10; position: absolute; left: -150px"
      >
        <v-card class="px-1 py-1 d-flex flex-column" style="gap: 4px">
          <div
            v-for="(item, index) in workspace"
            :key="index"
            @click="
              editedValue = item;
              saveValue();
            "
          >
            <v-hover v-slot="{ hover }" close-delay="100">
              <div
                :style="hover && 'background: var(--v-background_normal-base)'"
                class="d-flex align-center px-2 py-1"
                style="gap: 12px; z-index: 10"
              >
                <div
                  class="subtitle-1 font-weight-regular d-flex align-center"
                  :class="
                    item == editedValue
                      ? 'primary--text'
                      : 'onSurface_normal--text)'
                  "
                >
                  {{ item }}
                </div>
              </div>
            </v-hover>
          </div>
        </v-card>
      </div>
      <div
        v-if="isEditing && inputType === 'paymentMethod'"
        style="z-index: 10; position: absolute; left: -100px"
      >
        <v-card class="px-1 py-1 d-flex flex-column" style="gap: 4px">
          <div
            v-for="(item, index) in paymentMethod"
            :key="index"
            @click="
              editedValue = item;
              saveValue();
            "
          >
            <v-hover v-slot="{ hover }" close-delay="100">
              <div
                :style="hover && 'background: var(--v-background_normal-base)'"
                class="d-flex align-center px-2 py-1"
                style="gap: 12px; z-index: 10"
              >
                <div
                  class="subtitle-1 font-weight-regular d-flex align-center"
                  :class="
                    item == editedValue
                      ? 'primary--text'
                      : 'onSurface_normal--text)'
                  "
                >
                  {{ item }}
                </div>
              </div>
            </v-hover>
          </div>
        </v-card>
      </div>
      <div
        v-if="isEditing && inputType === 'shippingMethod'"
        style="z-index: 10; position: absolute; left: -80px"
      >
        <v-card class="px-1 py-1 d-flex flex-column" style="gap: 4px">
          <div
            v-for="(item, index) in shippingMethod"
            :key="index"
            @click="
              editedValue = item;
              saveValue();
            "
          >
            <v-hover v-slot="{ hover }" close-delay="100">
              <div
                :style="hover && 'background: var(--v-background_normal-base)'"
                class="d-flex align-center px-2 py-1"
                style="gap: 12px; z-index: 10"
              >
                <div
                  class="subtitle-1 font-weight-regular d-flex align-center"
                  :class="
                    item == editedValue
                      ? 'primary--text'
                      : 'onSurface_normal--text)'
                  "
                >
                  {{ item }}
                </div>
              </div>
            </v-hover>
          </div>
        </v-card>
      </div>
      <v-date-picker
        v-if="inputType === 'date'"
        v-model="editedValue"
        @change="saveValue"
        format="YYYY-MM-DD"
        class="font-weight-regular onSurface_normal--text d-flex align-center"
        locale="ko"
        show-current
        show-adjacent-months
      />
      <input
        v-if="inputType === 'int'"
        style="width: 100%"
        type="text"
        v-model="displayedValue"
        ref="inputField"
        @input="formatNumber"
        enterkeyhint="Save"
        @keyup.enter="saveValue"
        @focus="selectAll"
        class="font-weight-regular onSurface_normal--text d-flex align-center"
        :class="'text-' + align"
      />
      <input
        v-if="inputType === 'text'"
        style="width: 100%"
        type="text"
        v-model="editedValue"
        ref="inputField"
        enterkeyhint="Save"
        @keyup.enter="saveValue"
        @focus="selectAll"
        class="font-weight-regular onSurface_normal--text d-flex align-center"
        :class="'text-' + align"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    align: String,
    edit: Boolean,
    value: [String, Number, Boolean, Date],
    valueKey: String,
  },
  data() {
    return {
      inputType: "",
      isEditing: false, // 수정 모드인지 여부
      editedValue: this.value, // 실제 수정 중인 값
      displayedValue: this.getFormattedValue(), // 포맷팅된 값
      hasFocus: false, // 포커스 상태 관리
      status: [
        { icon: "edit-3", title: "Draft", text: "접수 전" },
        { icon: "upload-cloud", title: "Pending", text: "신규 주문" },
        { icon: "user-check", title: "Checking", text: "확인 중" },
        { icon: "repeat", title: "Hold", text: "수정 요청" },
        { icon: "credit-card", title: "Ready", text: "결제 확인" },
        { icon: "tool", title: "Progress", text: "작업 진행" },
        { icon: "check-square", title: "Finished", text: "작업 완료" },
        { icon: "truck", title: "Shipped", text: "출고 완료" },
        { icon: "x-octagon", title: "Completed", text: "상담 완료" },
      ], // status 선택지
      workspace: [
        "모델링",
        "메이드올 대구지사",
        "경희대학교 국제캠퍼스",
        "이노자드",
        "더엠제이",
      ],
      paymentMethod: ["네이버 결제", "현금 결제", "세금계산서"], // 예시 결제 옵션
      shippingMethod: ["택배", "고속버스", "방문수령", "퀵"], // 예시 배송 옵션
    };
  },
  computed: {
    hasValue() {
      return (
        this.value !== null && this.value !== undefined && this.value !== ""
      );
    },
    formattedValue() {
      return this.getFormattedValue();
    },
  },
  methods: {
    toggleEditMode() {
      if (!this.edit) {
        return this.$toasted.global.error("해당 값은 직접 수정이 불가합니다.");
      }
      this.isEditing = !this.isEditing;
      this.editedValue = this.value;
      this.displayedValue = this.getFormattedValue();
      this.$nextTick(() => {
        if (this.inputType === "int" || this.inputType === "text") {
          this.$refs.inputField.focus();
          this.$refs.inputField.select();
        }
      });
    },
    formatNumber(event) {
      let value = event.target.value;

      value = value.replace(/[^0-9-]/g, "");

      if (value.indexOf("-") > 0) {
        value = value.replace(/-/g, ""); // 모든 '-' 제거 후
        value = "-" + value; // 맨 앞에 '-' 추가
      }

      if (!isNaN(value) && value !== "-") {
        this.displayedValue = Number(value).toLocaleString("en-US");
      } else {
        this.displayedValue = value; // '-'만 입력되었을 때는 그대로 표시
      }

      this.editedValue = value !== "-" ? Number(value) : 0;
    },
    getFormattedValue() {
      switch (this.valueKey) {
        case "totalPrice":
        case "modelingPrice":
        case "printingPrice":
        case "shippingPrice":
        case "discountPrice":
        case "number":
          this.inputType = "int";
          return this.formatValue(this.value);
        case "createdAt":
        case "updatedAt":
        case "paidAt":
          this.inputType = "date";
          return this.formatDate(this.value, "YYYY-MM-DD HH:mm");
        case "dueAt":
        case "shipAt":
          this.inputType = "date";
          return this.formatDate(this.value, "YYYY-MM-DD");
        case "purpose":
        case "printmemo":
        case "address":
        case "trackingCode":
        case "text":
          this.inputType = "text";
          return this.value;
        case "status":
          this.inputType = "status";
          return this.status?.filter((v) => v.title == this.editedValue)[0]
            ?.text;
        case "workspace":
          this.inputType = "workspace";
          return this.value;
        case "paymentMethod":
          this.inputType = "paymentMethod";
          return this.value;
        case "shippingMethod":
          this.inputType = "shippingMethod";
          return this.value;
        default:
          return this.value;
      }
    },
    formatDate(date, format) {
      if (!date || isNaN(new Date(date).getTime())) return "-";
      this.editedValue = moment(date).format("YYYY-MM-DD");
      return moment(date).format(format);
    },
    formatValue(value) {
      if (!value) return "0";
      const numValue = typeof value === "string" ? Number(value) : value;
      return numValue.toLocaleString("en-US");
    },
    selectAll() {
      this.$refs.inputField.select();
    },
    saveValue() {
      this.$emit("update", this.editedValue);
      this.isEditing = false;
    },
    handleClickOutside(event) {
      const element = this.$el;
      if (!element.contains(event.target)) {
        this.isEditing = false; // 수정 모드 종료
      }
    },
  },
  watch: {
    value(newVal) {
      this.editedValue = newVal;
      this.displayedValue = this.getFormattedValue();
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped></style>
